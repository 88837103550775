<template>
    <OModal name="syntaxSetupCommon" @hide="() => { props.dataObject.save(); }">
        <div class="modal-dialog modal-lg" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">{{ $t('Syntax') }}</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" />
                </div>
                <div class="modal-body">
                    <div class="row">
                        <div class="col-auto">
                            <ODataLookup :dataObject="props.lookupObject" 
                                :bind="sel=>{ addField(sel) }">
                                <OColumn name="FieldName" width="300"/>
                                <template #target="{ target }">
                                    <button :ref="target" class="btn btn-sm btn-outline-primary">{{ $t('Select field') }}</button>
                                </template>
                            </ODataLookup>
                        </div>
                        <div class="col-3">
                            <select class="form-select form-select-sm" v-model="separator" id="separatorSelect">
                                <option value="noSep" v-if="separator == 'noSep'" selected disabled hidden>{{ $t('Choose separator') }}</option>
                                <option value="noSep" v-if="separator !== 'noSep'"></option>
                                <option value="-">-</option>
                                <option value="-">-</option>
                                <option value="_">_</option>
                                <option value="/">/</option>
                                <option value="#">#</option>
                                <option value=".">.</option>
                            </select>
                        </div>
                    </div>
                    <div class="row mt-2">
                        <div class="col-12">
                            <label>{{ $t('Syntax') }}</label>
                            <input class="form-control" v-model="props.dataObject.current.Syntax">
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button class="btn btn-outline-secondary" data-bs-dismiss="modal" >{{ $t('Close') }}</button>
                </div>
            </div>
        </div>
    </OModal>
</template>

<script setup>
    import { ref } from 'vue';

    const props = defineProps({
        dataObject: Object,
        lookupObject: Object
    })

    const separator = ref("noSep");

    const addField = (row) =>{
        let curSyntax = props.dataObject.current.Syntax ? props.dataObject.current.Syntax : "";
        props.dataObject.current.Syntax = curSyntax + (separator.value == "noSep" || !curSyntax ? "" : separator.value) + "[" + (row.IsProperty ? "Prop_" : "") + row.FieldName + "]";
    };
    
</script>